<template>
  <div>
    <portal to="page-top-title">New Subject</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/subjects" redirect="subjects-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" :max="64" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!categories.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="value" :model="formData.value" :min="3" :max="250" required/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="value_with_codes" title="Value with Shortcodes" :model="formData.value_with_codes" :min="3" :max="250">
              <template slot="info">
                <p>
                  <span>Shortcodes:</span>
                  <loader v-if="!shortcodes.length"/>
                  <code v-for="(shortcode, i) in shortcodes" :key="`shortcode-${i}`">{{ shortcode.code }}</code>
                </p>
              </template>
            </form-group-input>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Loader from '@/views/components/simple/Loader'
import Form from '@/modules/utils/Form'

export default {
  name: 'SubjectsNew',
  metaInfo () {
    return { title: 'Subjects | New' }
  },
  components: {
    Loader,
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  data () {
    return {
      categories: [],
      shortcodes: [],
      formData: {
        name: Form.types.input(),
        value: Form.types.input(),
        value_with_codes: Form.types.input(),
        active: Form.types.boolean(true),
        category_id: Form.types.select()
      }
    }
  },
  created () {
    this.loadCategories()
    this.loadShortcodes()
  },
  methods: {
    loadCategories () {
      this.$http
        .get('/subjects/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => { return { id: category.id, name: category.name } })

          this.categoryCheckSelected()
        })
    },
    loadShortcodes () {
      this.$http
        .get('/macros/shortcodes', { params: { type: 'subject' } })
        .then((res) => {
          const { data: { data } } = res

          this.shortcodes = data.map((code) => { return { code: code.code, name: code.title, descr: code.descr } })
        })
    },
    categoryCheckSelected () {
      const queryCatId = Number(this.$route.query.category)

      if (isNaN(queryCatId)) return

      const category = this.categories.find((category) => category.id === queryCatId)

      if (category) this.formData.category_id = Form.types.select(category)
    }
  }
}
</script>
